<template>
  <div class="bg-surface rounded-xl">
    <div class="p-4">
      <img
        v-if="
          file.customPreviewUrl ||
          file.previewUrl ||
          contentFileType === FileType.IMAGE
        "
        :src="file.customPreviewUrl || file.previewUrl || file.fileUrl"
        class="w-full h-full rounded-lg object-cover"
        :alt="file.title"
      />
      <video
        v-else-if="file.fileUrl && contentFileType === FileType.VIDEO"
        controls
        class="h-full w-full rounded-lg bg-disabled"
      >
        <source :src="`${file.fileUrl}#t=0.001`" />
      </video>
      <div
        v-else
        class="flex items-center justify-center w-full h-full rounded-lg bg-surface aspect-video p-8"
      >
        <FilePlaceholder v-if="contentFileType === FileType.FILE" />
        <VideoPlaceholder v-else />
      </div>
      <div v-if="file.showDownloadButton" class="mt-6">
        <Btn
          :size="BtnSize.LG"
          :variant="BtnVariant.FILLED"
          :color="BtnColor.PRIMARY"
          @click="downloadContentFile"
        >
          Download
        </Btn>
      </div>
      <div class="mt-5 font-bold text-2xl text-on-surface">
        {{ file.title }}
      </div>
      <div class="mt-2 text-sm text-on-surface-variant">
        {{ file.description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Btn, BtnSize, BtnColor, BtnVariant } from '~/components/btn';
import { setFileType } from '~/pages/_components/content-details/content.utils';
import { FileType } from '~/pages/_components/content-details/content.constants';
import { computed } from 'vue';
import FilePlaceholder from '~/assets/images/placeholders/file-placeholder.svg?component';
import VideoPlaceholder from '~/assets/images/placeholders/video-placeholder.svg?component';
import { downloadFile, getUrlFileExtension } from '~/utils/file';
import {
  createContentMetric,
  ContentMetricType,
  createContentLaunchMetric,
  LaunchMetricType,
} from '~/api/metrics';
import { ContentDto } from '~/api/contents';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '~/store/account';
import { AccountType } from '~/api/accounts';
import { HubDto } from '~/api/hubs';

type Props = {
  hub: HubDto;
  content: ContentDto;
  file: NonNullable<ContentDto['files']>[number];
};
const props = defineProps<Props>();

const { currentAccount } = storeToRefs(useAccountStore());

const contentFileType = computed<FileType>(() => {
  return setFileType(props.file.fileUrl);
});

const downloadContentFile = async () => {
  const fileName = `${props.file.title}.${getUrlFileExtension(
    props.file.fileUrl,
  )}`;

  createContentMetric(
    props.hub.slug,
    props.content.slug,
    ContentMetricType.FILE_DOWNLOAD,
  );
  if (
    props.hub.isLaunchEnabled &&
    currentAccount.value?.type === AccountType.STANDARD &&
    !props.content.launchData.completedAt
  ) {
    await createContentLaunchMetric(
      props.hub.slug,
      props.content.slug,
      LaunchMetricType.COMPLETED,
    );
    // eslint-disable-next-line vue/no-mutating-props
    props.content.launchData.completedAt = new Date();
  }
  downloadFile(props.file.fileUrl, fileName);
};
</script>
