import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "160",
  height: "160",
  fill: "none",
  viewBox: "0 0 160 160"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"80\" cy=\"80\" r=\"79.143\" fill=\"#F6F8FF\" stroke=\"#ECF2FF\" stroke-width=\"1.714\"></circle><rect width=\"105.143\" height=\"142.286\" x=\"151.143\" y=\"39.715\" fill=\"#fff\" stroke=\"#ECF2FF\" stroke-width=\"1.714\" rx=\"8.286\" transform=\"rotate(90 151.143 39.715)\"></rect><circle cx=\"80\" cy=\"86.286\" r=\"22.571\" stroke=\"#ECF2FF\" stroke-width=\"1.714\"></circle><path stroke=\"#ECF2FF\" stroke-width=\"1.714\" d=\"M86.792 85.581c.952.55.952 1.925 0 2.475l-9.916 5.725a1.43 1.43 0 0 1-2.142-1.238v-11.45c0-1.099 1.19-1.786 2.142-1.236z\"></path><path stroke=\"#ECF2FF\" stroke-linecap=\"round\" stroke-width=\"1.714\" d=\"M19.714 130h120\"></path><circle cx=\"124\" cy=\"130.286\" r=\"4.571\" fill=\"#ECF2FF\"></circle>", 6)
  ])))
}
export default { render: render }